import React from 'react';
import ReactDOM from 'react-dom/client';
import "@fontsource/titan-one"
import '@fontsource/open-sans/400.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
const theme = {
  fontFamily: 'Rajdhani, sans-serif',
  headings: { fontFamily: 'Rajdhani, sans-serif' },
  autoContrast: true,
  defaultMode: 'dark',
  colorScheme: 'dark',
  black: '#121212',
  white: '#f7f7f7',
  colors:{
    dark: [
      '#d5d7e0',
      '#acaebf',
      '#8c8fa3',
      '#666980',
      '#4d4f66',
      '#34354a',
      '#0a0a0a',
      '#141214',
      '#0c0d21',
      '#01010a',
    ],
    white: [
      "#f5f5f5",
      "#e7e7e7",
      "#cdcdcd",
      "#b2b2b2",
      "#9a9a9a",
      "#8b8b8b",
      "#848484",
      "#717171",
      "#656565",
      "#575757"
    ]
  },
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MantineProvider theme={ theme } defaultColorScheme='dark'>
      <App />
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
