import { FaWhatsapp, FaFacebook, FaInstagram, FaTwitter, FaYoutube, FaPinterest, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { Box, ActionIcon } from '@mantine/core';

const SocialNetworks = ({ localSettings }) =>
{
  return (
    <Box className='flex flex-row gap-2 my-2 justify-center'>
      { localSettings.whatsapps && localSettings.whatsapps.length > 0 && localSettings.whatsapps.map((whatsapp, index) => (
        <a key={ whatsapp._id } alt={ whatsapp.name } href={ `https://wa.me/${ whatsapp.number }?text=${ encodeURIComponent(whatsapp.msg) }` } target="_blank" rel="noreferrer">
          <ActionIcon variant="subtle" color='green' size='md'>
            <FaWhatsapp className='text-2xl' />
          </ActionIcon>
        </a>
      )) }
      { localSettings.socialNetworks && localSettings.socialNetworks.length > 0 && localSettings.socialNetworks.map((social) => (

        social.url !== '' && (
          <a key={ social._id } href={ social.url } target="_blank" rel="noreferrer">
            <ActionIcon variant="subtle" color='green' size='md'>
              { social.icon === 'fa-facebook' && <FaFacebook className='text-2xl' /> }
              { social.icon === 'fa-instagram' && <FaInstagram className='text-2xl' /> }
              { social.icon === 'fa-twitter' && <FaTwitter className='text-2xl' /> }
              { social.icon === 'fa-youtube' && <FaYoutube className='text-2xl' /> }
              { social.icon === 'fa-pinterest' && <FaPinterest className='text-2xl' /> }
              { social.icon === 'fa-linkedin' && <FaLinkedin className='text-2xl' /> }
              { social.icon === 'fa-tiktok' && <FaTiktok className='text-2xl' /> }
            </ActionIcon>
          </a>
        )

      )) }
    </Box>
  )
}

export default SocialNetworks