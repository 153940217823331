import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mantine/core'
import SettingContext from '../../context/Setting/settingContext';
import ToggleButton from './ToggleButton'
import Logo from '../../assets/Logo.png'
import SocialNetworks from '../SocialNetworks';

const Header = () =>
{
  const { settings } = useContext(SettingContext);
  const [ localSettings, setLocalSettings ] = useState({
    socialNetworks: [],
    branches: [],
    privacyStatement: "",
    contactEmail: "",
    whatsapps: []
  });

  useEffect(() =>
  {
    if (settings) {
      setLocalSettings(settings);
    }
  }, [ settings ]);


  return (
    <header className='h-16 flex flex-row items-center justify-between border-b print:hidden '>
      <Box className='flex flex-row items-center justify-start'>
        <Link to='/'>
          <img src={ Logo } className='mx-2 object-contain	w-16 h-16 p-1' alt='Logo' onClick={ () => { } } />
        </Link>
      </Box>
      <div className='w-4/12 lg:w-2/12 flex flew-row justify-end items-center'>
        <SocialNetworks localSettings={ localSettings } />
        {/* <ToggleButton /> */ }

      </div>


    </header>
  )
}

export default Header