import React, { useEffect, useState, useContext } from 'react';
import MenuSection from '../../components/Home/MenuSection';
import Footer from '../../components/Footer/Footer';
import { get_products_by_cat, get_recommended_products } from '../../utils/apis/products';
import SettingContext from '../../context/Setting/settingContext';
import { get_categories } from '../../utils/apis/categories';
import Slider from '../../components/Slider/Slider';
import { get_banners, get_gallery } from '../../utils/apis/sliders';
import Masonry from 'react-masonry-css';
import Example from '../../assets/example.jpg';

const Home = () => {
  const { getSettings, settings } = useContext(SettingContext);
  const [recommended, setRecommended] = useState([]);
  const [categories, setCategories] = useState([]);
  const [menu, setMenu] = useState({});
  const [sliders, setSliders] = useState([]);
  const [gallery, setGallery] = useState([]);

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    800: 2,
    600: 1,
    500: 1
  };

  useEffect(() => {
    getSettings()
    const fetchAllData = async () => {
      const resCategories = await get_categories();
      setCategories(resCategories.results);
      let newMenu = {};
      for (const cat of resCategories.results) {
        const productsByCategory = await get_products_by_cat(cat._id);
        newMenu[cat.name] = productsByCategory.results; // Asegúrate de acceder a la propiedad 'result'
      }
      setMenu(newMenu);

      const resRecommended = await get_recommended_products();
      setRecommended(resRecommended.results);

      const resBanners = await get_banners();
      setSliders(resBanners.results);

      const resGallery = await get_gallery();
      setGallery(resGallery.results);
    };
    fetchAllData();
  }, []);

  const renderMenuSections = () => {
    let content = [];
    if (recommended.length) {
      content.push(<MenuSection key="recommended" title="Lo mas pedido" items={recommended} />);
    }
  
    categories.forEach((category, index) => {
      content.push(<MenuSection key={category.name} title={category.name} items={menu[category.name]} />);
      // Calcular el índice de la galería basado en la categoría index
      const galleryIndex = Math.floor(index / 2);
      // Asegúrate de que el índice de la galería sea menor que 5 y que el elemento exista en el arreglo gallery
      if ((index) % 2 === 0 && galleryIndex < 5 && gallery[galleryIndex]) {
        content.push(
          <div key={`gallery-${index}`} className='border rounded-lg w-auto mx-2'>
            <img src={`${process.env.REACT_APP_API_URL}/api/v1/uploads/${gallery[galleryIndex]._id}`} alt="Gallery" className='border rounded-lg w-full' />
          </div>
        );
      }
    });
    return content;
  
  };

  return (
    <div className='grid grid-cols-1 w-screen'>
      <Slider sliders={sliders} />
      <div className='w-full'>
        <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid my-2" columnClassName="my-masonry-grid_column">
          {renderMenuSections()}
        </Masonry>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
